import { EventBus } from "@/event-bus";
import {
  paginationDefault,
  ReconciliationData,
  Step
} from "@/interfaces/retailSalesReconciliation";
import { retailSalesReconciliationService } from "@/services/retailSalesReconciliation.service";
import { PageNavAction } from "@/types/types";
import { TablePagination } from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import LoadingWindowComponent from "../../loadingWindow/loadingWindow.component";
import BatchReconciliationComponent from "./batchReconciliation/BatchReconciliation.component";
import ChooseDayComponent from "./chooseDay/ChooseDay.component";
import ReconciliationSummaryComponent from "./reconciliationSummary/ReconciliationSummary.component";
import SalesReconciliationComponent from "./salesReconciliation/SalesReconciliation.component";
import Template from "./Stepper.template.vue";

@Component({
  mixins: [Template],
  components: {
    ChooseDayComponent,
    BatchReconciliationComponent,
    SalesReconciliationComponent,
    ReconciliationSummaryComponent
  }
})
export default class StepperComponent extends Vue {
  @Prop({ required: true })
  public currentStep!: Step;
  @Prop({ required: true })
  public selectedDate!: Date;
  @Prop({ required: true })
  public reconciliationData!: ReconciliationData;
  @Prop({ required: true })
  public pagination!: TablePagination;
  @Prop({ default: false })
  public loading!: boolean;

  public step = Step;

  @Action("setPageNav", { namespace: "PageNavModule" })
  protected setPageNav!: PageNavAction;

  public mounted() {
    this.setPageNav({
      title: "metrc.retail_sales_reconciliation.page_nav"
    });

    if (this.currentStep === Step.BATCH_RECONCILIATION) {
      this.setBatchReconciliationStep(this.selectedDate);
      this.updatePagination({
        ...this.pagination,
        totalItems: Object.keys(this.reconciliationData.batches_to_reconcile)
          .length
      });
    } else if (this.currentStep === Step.SALES_RECONCILIATION) {
      this.goToSaleReconciliation();
    }
  }

  public goToChooseDate() {
    this.$emit("change", { step: Step.CHOOSE_DAY });
  }

  public async goToBatchReconciliation(selectedDate: Date): Promise<void> {
    const date = selectedDate || this.selectedDate;
    await this.setBatchReconciliationStep(date);
    this.updatePagination({
      ...paginationDefault,
      totalItems: Object.keys(this.reconciliationData.batches_to_reconcile)
        .length
    });
  }

  public async goToSaleReconciliation(): Promise<void> {
    if (this.isNextStep(Step.SALES_RECONCILIATION)) {
      const success = await this.updateReconciliationData(this.selectedDate);
      if (success) {
        this.$emit("change", { step: Step.SALES_RECONCILIATION });
      }
    } else {
      this.$emit("change", { step: Step.SALES_RECONCILIATION });
    }
  }

  public async goToSummary(): Promise<void> {
    if (this.isNextStep(Step.SUMMARY)) {
      const success = await this.updateReconciliationData(this.selectedDate);
      if (success) {
        this.$emit("change", { step: Step.SUMMARY });
      }
    } else {
      this.$emit("change", { step: Step.SUMMARY });
    }
  }

  public finishReconciliation(): void {
    this.$emit("change", { date: new Date(), step: Step.CHOOSE_DAY });
  }

  public updatePagination(pagination: TablePagination) {
    this.$emit("updatePagination", pagination);
  }

  protected async setBatchReconciliationStep(
    selectedDate: Date
  ): Promise<void> {
    if (this.isNextStep(Step.BATCH_RECONCILIATION)) {
      const success = await this.updateReconciliationData(selectedDate);

      if (success) {
        this.$emit("change", {
          step: Step.BATCH_RECONCILIATION,
          date: selectedDate
        });
      }
    } else {
      this.$emit("change", { step: Step.BATCH_RECONCILIATION });
    }
  }

  protected isNextStep(step: Step): boolean {
    return this.currentStep < step;
  }

  protected async updateReconciliationData(date: Date): Promise<boolean> {
    this.showLoadingWindow();
    const response = await retailSalesReconciliationService.getReconciliation(
      date
    );
    if (response.success) {
      this.$emit("updateReconciliationData", response.data);
      this.hideLoadingWindow();
    } else {
      this.updateLoadingWindow({ errorList: response.errors });
    }

    return response.success;
  }

  protected showLoadingWindow(): void {
    this.$modals
      .load(LoadingWindowComponent, {
        closable: false,
        size: "fit",
        positionY: "center",
        positionX: "center",
        backdrop: true
      })
      .catch(() => {
        // nothing to do
      });
  }

  protected updateLoadingWindow(event: {
    errorList: Array<{ message: string }>;
    className?: string;
  }): void {
    EventBus.$emit("mtrcLoadingEvent", {
      show: true,
      ...event
    });
  }

  protected hideLoadingWindow(): void {
    EventBus.$emit("mtrcLoadingEvent", {
      show: false
    });
  }
}

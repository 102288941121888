import {
  ParsedReconciliationBatch,
  ReconciliationBatch,
  ReconciliationSummary,
  SummaryData
} from "@/interfaces/retailSalesReconciliation";
import { BatchesActionCallback } from "@/interfaces/retailSalesReconciliation";
import { BatchReconciliationMetadata } from "@/metadata/retailSalesReconciliation";
import { retailSalesReconciliationActionService } from "@/services/retailSalesReconciliation.action.service";
import {
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import reduce from "lodash/reduce";
import { Component, Prop, Vue } from "vue-property-decorator";
import SummaryComponent from "../summary/Summary.component";
import Template from "./BatchReconciliation.template.vue";

@Component({
  mixins: [Template],
  components: {
    SummaryComponent,
    TableComponent
  }
})
export default class BatchReconciliationComponent extends Vue {
  public get batches() {
    return reduce(
      this.batchesToReconcile,
      (acc: ParsedReconciliationBatch[], batch: ReconciliationBatch) => {
        acc.push({
          batch_uid: batch.batch_uid,
          product_name: batch.local_batch_information.product_name,
          quantity_of_sales: batch.transactions_with_errors,
          tracking_id: batch.local_batch_information.tracking_id || "-",
          has_errors: batch.has_errors,
          status: batch.has_errors ? "PENDING" : "IN_SYNC"
        });
        return acc;
      },
      []
    ).slice(
      this.pagination.from > 0 ? this.pagination.from - 1 : 0,
      this.pagination.to
    );
  }

  public get totalsSummary(): SummaryData[] {
    return [
      {
        label: this.$t(
          "metrc.retail_sales_reconciliation.batches.total_checked"
        )
          .toString()
          .toUpperCase(),
        value: this.reconciliationSummary.cannabis_batches_checked
      },
      {
        label: this.$t("metrc.retail_sales_reconciliation.batches.to_reconcile")
          .toString()
          .toUpperCase(),
        value: this.reconciliationSummary.cannabis_batches_with_errors
      },
      {
        label: this.$t(
          "metrc.retail_sales_reconciliation.batches.batches_in_sync"
        )
          .toString()
          .toUpperCase(),
        value:
          this.reconciliationSummary.cannabis_batches_checked -
          this.reconciliationSummary.cannabis_batches_with_errors
      }
    ];
  }

  @Prop({ required: true })
  public batchesToReconcile!: { [batchUid: string]: ReconciliationBatch };
  @Prop({ required: true })
  public reconciliationSummary!: ReconciliationSummary;
  @Prop({ required: true })
  public pagination!: TablePagination;
  @Prop({ default: false })
  public loading!: boolean;

  public headers: TableHeader[] = [];
  public rowActions: TableAction[] = [];
  public actionCallback: BatchesActionCallback = {
    reconciliate: this.reconciliateBatch,
    reconciliateVisibility: this.reconciliateVisibility
  };

  public nextStep() {
    this.$emit("nextStep");
  }

  public prevStep() {
    this.$emit("prevStep");
  }

  public reconciliateBatch(batch: ParsedReconciliationBatch) {
    this.$store.dispatch("RouterModule/go", {
      name: "retail-sales-reco-batch",
      params: {
        batchuid: batch.batch_uid
      }
    });
  }

  public reconciliateVisibility(arg: {
    item: ParsedReconciliationBatch;
  }): boolean {
    return arg.item.has_errors;
  }

  protected changePagination(pagination: TablePagination) {
    this.$emit("updatePagination", pagination);
  }

  protected async mounted() {
    this.headers = BatchReconciliationMetadata;
    this.rowActions = retailSalesReconciliationActionService.getBatchesRowActions(
      this.actionCallback
    );
  }
}

import { render, staticRenderFns } from "./Stepper.template.vue?vue&type=template&id=36b28ff2&scoped=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b28ff2",
  null
  
)

export default component.exports
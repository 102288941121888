import {
  HelixDatePickerOptions,
  PickerOptions,
  TablePagination
} from "helix-vue-components";

export interface BatchesActionCallback {
  reconciliate: (batch: ParsedReconciliationBatch) => void;
  reconciliateVisibility: (arg: { item: ParsedReconciliationBatch }) => boolean;
}

export enum Step {
  "CHOOSE_DAY" = 1,
  "BATCH_RECONCILIATION" = 2,
  "SALES_RECONCILIATION" = 3,
  "SUMMARY" = 4
}

export interface ReconciliationData {
  synced: ReconciliationSynced[];
  errors: ReconciliationError[];
  batches_to_reconcile: {
    [batchUid: string]: ReconciliationBatch;
  };
  duplicate_orders: ReconciliationDuplicateOrder[];
  summary: ReconciliationSummary;
}

export interface ReconciliationSynced {
  some_value: string;
}

export interface ReconciliationError {
  transaction: ReconciliationTransaction;
  status?: string;
  message: string;
}

export interface ReconciliationBatch {
  batch_uid: string;
  errors: string[];
  orders_with_errors?: number;
  transactions_with_errors: number;
  local_batch_information: LocalBatchInformation;
  remote_batch_information?: RemoteBatchInformation;
  has_errors: boolean;
}

export interface LocalBatchInformation {
  batch_uid: string;
  tracking_id: string | null;
  product_name: string;
  metrc_item_mapped: string | null;
  batch_type: string | null;
  strain: string | null;
  quantity: number;
  unit: string;
  metrc_weighable: boolean;
  total_usable_weight: number;
  usable_weight: number;
  usable_weight_unit: string;
  package_weight: number;
  package_weight_unit: string;
}

export interface RemoteBatchInformation {
  tag?: string;
  metrc_item?: string;
  metrc_category?: string;
  strain?: string;
  tag_status?: string;
  quantity?: number;
  unit?: string;
  quantity_conversion?: boolean;
  item_unit_thc_content?: number;
  item_unit_thc_content_unit_of_measure_name?: string;
  item_unit_weight?: number;
  item_unit_weight_unit_of_measure_name?: string;
}

export interface ParsedReconciliationBatch {
  batch_uid: string;
  tracking_id: string;
  product_name: string;
  quantity_of_sales: number;
  has_errors: boolean;
  status: string;
}

export interface ReconciliationDuplicateOrder {
  duplicates: DuplicateOrder[];
  license_number: string;
  order: BaseOrder;
  transaction_date_time: string;
}

export interface Customer {
  customer_id: string;
  email: string | null;
  first_name: string;
  id: number;
  last_name: string;
}

export interface BaseOrderItem {
  batch_uid: string;
  line_item_discounts: number;
  price_after_discounts: number;
  price: number;
  product: string;
  unit: string;
  quantity: number;
  tracking_id: string;
}

export interface BaseOrderUser {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  nickname: string;
}

export interface BaseOrder {
  created_at: string;
  customer_id: number | null;
  customer: Customer;
  items: BaseOrderItem[] | null;
  sub_total: number | null;
  total: number | null;
  transaction_id: string;
  transaction_type: string;
  trc_error: string | null;
  trc_receipt_id: string;
  trc_status: string;
  user_id: number | null;
  user?: BaseOrderUser | null;
}

export interface DuplicateOrder {
  archived_date: string | null;
  caregiver_license_number: string;
  id: number;
  identification_method: string;
  is_final: boolean;
  last_modified: string;
  patient_license_number: string;
  receipt_number: string;
  recorded_by_user_name: string;
  recorded_date_time: string;
  sales_customer_type: string;
  sales_date_time: string;
  total_packages: number;
  total_price: number;
  transactions: null;
}

export interface ParsedDuplicatedOrder {
  customer: string;
  date_time: string;
  duplicates: DuplicateOrder[];
  license_number: string;
  metrc_receipt_id: string;
  order?: BaseOrder;
  sale_type: string;
  status: string;
  subtotal: string;
  transaction_date_time: string;
  transaction_id: string;
}

export interface ReconciliationTransaction {
  created_at: string;
  customer: Customer;
  customer_id: number;
  sub_total: number;
  total: number;
  transaction_id: string;
  transaction_type: string;
  trc_error: string;
  trc_receipt_id: number | null;
  trc_status: string;
  user_id: number;
  user?: BaseOrderUser | null;
}

export interface ReconciliationSummary {
  cannabis_batches_with_errors: number;
  cannabis_batches_checked: number;
  total_orders_to_reconcile: number;
  total_transactions_to_reconcile: number;
  total_transactions: number;
}

export interface ReconciliationDataResponse {
  success: boolean;
  data: ReconciliationData | null;
  errors: Array<{ message: string }>;
}

export interface ReconciliationState {
  step: Step;
  date?: Date;
}

export interface HelixPickerOptions extends HelixDatePickerOptions {
  pickerOptions?: PickerOptions;
}

export interface BatchReconciledResponse {
  batch_uid: string;
  response: object;
  success: boolean;
}
export interface SummaryData {
  label: string;
  value: number;
}

export const reconciliationDataDefault: ReconciliationData = {
  synced: [],
  errors: [],
  batches_to_reconcile: {},
  duplicate_orders: [],
  summary: {
    total_orders_to_reconcile: 0,
    cannabis_batches_with_errors: 0,
    cannabis_batches_checked: 0,
    total_transactions: 0,
    total_transactions_to_reconcile: 0
  }
};

export const paginationDefault: TablePagination = {
  itemsPerPage: 10,
  totalItems: 0,
  currentPage: 1,
  itemsPerPageOptions: [10, 20, 50, 100],
  from: 1,
  to: 10
};

export type ReconciliationWithEmbeddedOrder = ReconciliationDuplicateOrder & {
  order: BaseOrder & { order: BaseOrder };
};

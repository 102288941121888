import { render, staticRenderFns } from "./ChooseDay.template.vue?vue&type=template&id=5b3f98e2&scoped=true&"
var script = {}
import style0 from "./ChooseDay.template.vue?vue&type=style&index=0&id=5b3f98e2&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b3f98e2",
  null
  
)

export default component.exports
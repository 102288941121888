import { ParsedReconciliationBatch } from "@/interfaces/retailSalesReconciliation";
import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const BatchReconciliationMetadata: TableHeader[] = [
  {
    value: "batch_uid",
    label: i18n
      .t("metrc.retail_sales_reconciliation.batches.batch_uid")
      .toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string
  },
  {
    value: "tracking_id",
    label: i18n
      .t("metrc.retail_sales_reconciliation.batches.secondary_id")
      .toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string
  },
  {
    value: "product_name",
    label: i18n
      .t("metrc.retail_sales_reconciliation.batches.product")
      .toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string
  },
  {
    value: "quantity_of_sales",
    label: i18n
      .t("metrc.retail_sales_reconciliation.batches.quantity_of_sales")
      .toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string
  },
  {
    value: "status",
    label: i18n
      .t("metrc.retail_sales_reconciliation.batches.status")
      .toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.enumText,
    enum: {
      PENDING: "metrc.retail_sales_reconciliation.batches.pending",
      IN_SYNC: "metrc.retail_sales_reconciliation.batches.in_sync"
    },
    conditionalClassFn: (batch: ParsedReconciliationBatch) => {
      return `statusLabel ${batch.status.toLowerCase()}`;
    }
  }
];

export const SalesReconciliationMetadata: TableHeader[] = [
  {
    value: "date_time",
    label: String(i18n.t("reconciliation.sales.date_time")),
    fieldComponent: TableFieldType.fnsDate
  },
  {
    value: "customer",
    label: String(i18n.t("reconciliation.sales.customer")),
    fieldComponent: TableFieldType.string
  },
  {
    value: "transaction_id",
    label: String(i18n.t("reconciliation.sales.transaction_id")),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "metrc_receipt_id",
    label: String(i18n.t("reconciliation.sales.metrc_receipt_id")),
    fieldComponent: TableFieldType.string
  },
  {
    value: "subtotal",
    label: String(i18n.t("reconciliation.sales.subtotal")),
    fieldComponent: TableFieldType.currency
  },
  {
    value: "sale_type",
    label: String(i18n.t("reconciliation.sales.sale_type")),
    fieldComponent: TableFieldType.string
  },
  {
    value: "status",
    label: String(i18n.t("reconciliation.sales.status")),
    fieldComponent: TableFieldType.enumText,
    conditionalClassFn: arg => {
      return `${arg.status.toLowerCase()}`;
    },
    enum: {
      PENDING_INFORMATION: i18n.t("reconciliation.status.pending"),
      NOT_NEEDED: i18n.t("reconciliation.status.not_needed"),
      TRC_ERROR: i18n.t("reconciliation.status.trc_error"),
      UPLOAD: i18n.t("reconciliation.status.uploaded"),
      SUCCESSFUL: i18n.t("reconciliation.status.possible_duplicate")
    },
    class: "tdt__headers__fieldLong"
  }
];

export const SummaryReconciliationMetadata: TableHeader[] = [
  {
    value: "transaction_id",
    label: String(i18n.t("reconciliation.sales.transaction_id")),
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "customer",
    label: String(i18n.t("reconciliation.sales.customer")),
    fieldComponent: TableFieldType.string
  },
  {
    value: "user_name",
    label: String(i18n.t("reconciliation.sales.user")),
    fieldComponent: TableFieldType.string
  },
  {
    value: "date_time",
    label: String(i18n.t("reconciliation.sales.date_time")),
    fieldComponent: TableFieldType.fnsDate
  },
  {
    value: "status",
    label: String(i18n.t("reconciliation.sales.status")),
    fieldComponent: TableFieldType.enumText,
    conditionalClassFn: arg => {
      return arg.status.toLowerCase();
    },
    enum: {
      IN_SYNC: i18n.t("reconciliation.status.in_sync"),
      TRC_ERROR: i18n.t("reconciliation.status.trc_error"),
      NOT_SYNC: i18n.t("reconciliation.status.error")
    },
    class: "tdt__headers__fieldLong"
  }
];

export const DuplicatedsTableHeaders: TableHeader[] = [
  {
    label: i18n.t("reconciliation.sales.date_time").toString(),
    fieldComponent: TableFieldType.fnsDate,
    value: "sales_date_time"
  },
  {
    label: i18n.t("reconciliation.sales.metrc_receipt_id").toString(),
    fieldComponent: TableFieldType.string,
    value: "id"
  },
  {
    label: i18n.t("reconciliation.sales.customer").toString(),
    fieldComponent: TableFieldType.string,
    value: "patient_license_number"
  },
  {
    label: i18n.t("reconciliation.sales.total").toString(),
    fieldComponent: TableFieldType.currency,
    value: "total_price"
  }
];
export const BaseOrderDuplicateTableHeaders: TableHeader[] = [
  {
    label: i18n.t("reconciliation.sales.date_time").toString(),
    fieldComponent: TableFieldType.fnsDate,
    value: "date_time"
  },
  {
    label: i18n.t("reconciliation.sales.customer").toString(),
    fieldComponent: TableFieldType.string,
    value: "customer"
  },
  {
    label: i18n.t("reconciliation.sales.transaction_id").toString(),
    fieldComponent: TableFieldType.string,
    value: "transaction_id"
  },
  {
    label: i18n.t("reconciliation.sales.metrc_receipt_id").toString(),
    fieldComponent: TableFieldType.string,
    value: "metrc_receipt_id"
  },
  {
    label: i18n.t("reconciliation.sales.subtotal").toString(),
    fieldComponent: TableFieldType.currency,
    value: "subtotal"
  },
  {
    label: i18n.t("reconciliation.sales.sale_type").toString(),
    fieldComponent: TableFieldType.string,
    value: "sale_type"
  }
];

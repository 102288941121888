import { BatchesActionCallback } from "@/interfaces/retailSalesReconciliation";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { TableAction } from "helix-vue-components";

class RetailSalesReconciliationActionService {
  public getBatchesRowActions(callback: BatchesActionCallback): TableAction[] {
    return [
      {
        icon: "fal fa-tools",
        label: "batches",
        class: "primary-base",
        visibleCondition: callback.reconciliateVisibility,
        action: callback.reconciliate
      }
    ];
  }

  public getRowActions(): TableAction[] {
    return [
      {
        icon: "fal fa-tools",
        action: async arg => {
          await store.dispatch(
            "SaleReconciliationModule/storeSaleDuplicates",
            arg
          );
          store.dispatch("RouterModule/go", {
            name: "possible-duplicates"
          });
        },
        tooltip: i18n.t("reconciliation.sales.possible_duplicates").toString(),
        class: "primary-base",
        visibleCondition: arg => {
          return arg.item.status === "SUCCESSFUL";
        }
      }
    ];
  }
}

export const retailSalesReconciliationActionService = new RetailSalesReconciliationActionService();

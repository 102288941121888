import {
  ReconciliationData,
  ReconciliationDuplicateOrder,
  ReconciliationError,
  SummaryData
} from "@/interfaces/retailSalesReconciliation";
import { SummaryReconciliationMetadata } from "@/metadata/retailSalesReconciliation";
import {
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import SummaryComponent from "../summary/Summary.component";
import Template from "./ReconciliationSummary.template.vue";
@Component({
  mixins: [Template],
  components: {
    TableComponent,
    SummaryComponent
  }
})
export default class ReconciliationSummaryComponent extends Vue {
  @Prop({ required: true })
  public reconciliationData!: ReconciliationData;
  public headers: TableHeader[] = SummaryReconciliationMetadata;
  public items: any[] = [];
  public rowActions: TableAction[] = [];
  public loading: boolean = false;
  public pagination: TablePagination | null = null;
  public data: any[] = [];
  public tablePagination = {
    itemsPerPage: 10,
    totalItems: 0,
    currentPage: 1,
    itemsPerPageOptions: [10, 20, 50, 100],
    from: 1,
    to: 10
  };

  public finishReconciliation() {
    this.$emit("nextStep");
  }

  public prevStep() {
    this.$emit("prevStep");
  }

  public parseSalesReconciliationsErrors(
    errors: ReconciliationError[],
    duplicates: ReconciliationDuplicateOrder[]
  ) {
    const parsedErrors = errors.map((reconciliation, index) => {
      return {
        customer: `${reconciliation.transaction.customer.first_name} ${
          reconciliation.transaction.customer.last_name
        }`,
        date_time: reconciliation.transaction.created_at,
        duplicates: null,
        license_number: null,
        metrc_receipt_id: reconciliation.transaction.trc_receipt_id,
        order: null,
        sale_type: reconciliation.transaction.transaction_type,
        status:
          reconciliation.status === "TRC_ERROR" ? "TRC_ERROR" : "NOT_SYNC",
        subtotal: reconciliation.transaction.sub_total.toFixed(2),
        transaction_date_time: null,
        transaction_id: reconciliation.transaction.transaction_id,
        user_name: `${reconciliation.transaction.user!.first_name} 
          ${reconciliation.transaction.user!.last_name}`
      };
    });

    const parsedDuplicateds = duplicates.map((duplicatedOrder, index) => {
      return {
        customer: `${duplicatedOrder.order.customer.first_name} 
          ${duplicatedOrder.order.customer.last_name}`,
        date_time: duplicatedOrder.transaction_date_time,
        duplicates: duplicatedOrder.duplicates.map(duplicate => ({
          customer: `${duplicatedOrder.order.customer.first_name} 
            ${duplicatedOrder.order.customer.last_name}`,
          date_time: duplicatedOrder.transaction_date_time,
          id: duplicate.id,
          metrc_receipt_id: duplicatedOrder.order.trc_receipt_id,
          sale_type: duplicatedOrder.order.transaction_type,
          subtotal: duplicatedOrder.order.sub_total!.toFixed(2) || null,
          transaction_id: duplicatedOrder.order.transaction_id
        })),
        license_number: duplicatedOrder.license_number,
        metrc_receipt_id: duplicatedOrder.order.trc_receipt_id,
        order: duplicatedOrder.order,
        sale_type: duplicatedOrder.order.transaction_type,
        status:
          duplicatedOrder.order.trc_status === "SUCCESSFUL"
            ? "NOT_SYNC"
            : "IN_SYNC",
        subtotal: duplicatedOrder.order.sub_total!.toFixed(2) || null,
        transaction_date_time: duplicatedOrder.transaction_date_time,
        transaction_id: duplicatedOrder.order.transaction_id,
        user_name: `${duplicatedOrder.order.user!.first_name} 
          ${duplicatedOrder.order.user!.last_name}`
      };
    });

    return [...parsedErrors, ...parsedDuplicateds];
  }

  public get totalsSummary(): SummaryData[] {
    return [
      {
        label: this.$t("reconciliation.sales.total_checked")
          .toString()
          .toUpperCase(),
        value: this.reconciliationData.summary.total_transactions
      },
      {
        label: this.$t("reconciliation.sales.to_reconcile")
          .toString()
          .toUpperCase(),
        value: this.reconciliationData.summary.total_transactions_to_reconcile
      },
      {
        label: this.$t("reconciliation.sales.sales_in_sync")
          .toString()
          .toUpperCase(),
        value:
          this.reconciliationData.summary.total_transactions -
          this.reconciliationData.summary.total_transactions_to_reconcile
      }
    ];
  }

  protected changePagination(pagination: TablePagination) {
    this.loading = true;
    this.items = this.data.slice(
      (pagination.currentPage - 1) * pagination.itemsPerPage,
      (pagination.currentPage - 1) * pagination.itemsPerPage +
        pagination.itemsPerPage
    );
    this.loading = false;
  }

  protected async mounted() {
    this.loading = true;
    this.data = this.parseSalesReconciliationsErrors(
      this.reconciliationData.errors,
      this.reconciliationData.duplicate_orders
    );
    this.items = this.data.slice(0, this.tablePagination.itemsPerPage);
    this.pagination =
      this.data.length > 10
        ? { ...this.tablePagination, totalItems: this.data.length }
        : null;

    this.loading = false;
  }
}

import {
  ParsedDuplicatedOrder,
  ReconciliationData,
  ReconciliationDuplicateOrder,
  ReconciliationError,
  SummaryData
} from "@/interfaces/retailSalesReconciliation";
import { SalesReconciliationMetadata } from "@/metadata/retailSalesReconciliation";
import { retailSalesReconciliationActionService } from "@/services/retailSalesReconciliation.action.service";
import {
  TableAction,
  TableComponent,
  TableHeader,
  TableItem,
  TablePagination
} from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import SummaryComponent from "../summary/Summary.component";
import Template from "./SalesReconciliation.template.vue";

@Component({
  mixins: [Template],
  components: {
    TableComponent,
    SummaryComponent
  }
})
export default class SalesReconciliationComponent extends Vue {
  @Prop({ default: false })
  public loading!: boolean;
  @Prop({ required: true })
  public reconciliationData!: ReconciliationData;

  public duplicatedOrders: ParsedDuplicatedOrder[] = [];

  public headers: TableHeader[] = SalesReconciliationMetadata;
  public data: TableItem[] = [];
  public items: TableItem[] = [];
  public rowActions: TableAction[] = [];
  public pagination: TablePagination | null = null;
  public tablePagination = {
    itemsPerPage: 10,
    totalItems: 0,
    currentPage: 1,
    itemsPerPageOptions: [10, 20, 50, 100],
    from: 1,
    to: 10
  };

  public nextStep() {
    this.$emit("nextStep");
  }

  public prevStep() {
    this.$emit("prevStep");
  }

  public parseSalesReconciliationsErrors(
    errors: ReconciliationError[],
    duplicates: ReconciliationDuplicateOrder[]
  ) {
    const parsedErrors = errors.map((reconciliation, index) => {
      return {
        date_time: reconciliation.transaction.created_at,
        customer: `${reconciliation.transaction.customer.first_name} ${
          reconciliation.transaction.customer.last_name
        }`,
        transaction_id: reconciliation.transaction.transaction_id,
        metrc_receipt_id: reconciliation.transaction.trc_receipt_id,
        subtotal: reconciliation.transaction.sub_total.toFixed(2),
        sale_type: this.$t(
          `metrc.retail_sales_reconciliation.sales_type.${
            reconciliation.transaction.transaction_type
          }`
        ).toString(),
        status: reconciliation.status,
        order: null,
        duplicates: null,
        license_number: null,
        transaction_date_time: null
      };
    });

    const parsedDuplicateds = duplicates.map((duplicatedOrder, index) => {
      return {
        date_time: duplicatedOrder.transaction_date_time,
        customer: `${duplicatedOrder.order.customer.first_name} ${
          duplicatedOrder.order.customer.last_name
        }`,
        transaction_id: duplicatedOrder.order.transaction_id,
        metrc_receipt_id: duplicatedOrder.order.trc_receipt_id,
        subtotal: duplicatedOrder.order.sub_total!.toFixed(2) || null,
        sale_type: this.$t(
          `metrc.retail_sales_reconciliation.sales_type.${
            duplicatedOrder.order.transaction_type
          }`
        ).toString(),
        status: duplicatedOrder.order.trc_status,
        order: duplicatedOrder.order,
        duplicates: duplicatedOrder.duplicates.map(duplicate => ({
          id: duplicate.id,
          sales_date_time: duplicatedOrder.transaction_date_time,
          customer: `${duplicatedOrder.order.customer.first_name} ${
            duplicatedOrder.order.customer.last_name
          }`,
          transaction_id: duplicatedOrder.order.transaction_id,
          metrc_receipt_id: duplicatedOrder.order.trc_receipt_id,
          patient_license_number: duplicate.patient_license_number,
          total_price: duplicate.total_price!.toFixed(2) || null,
          sale_type: this.$t(
            `metrc.retail_sales_reconciliation.sales_type.${
              duplicatedOrder.order.transaction_type
            }`
          ).toString()
        })),
        license_number: duplicatedOrder.license_number,
        transaction_date_time: duplicatedOrder.transaction_date_time
      };
    });

    return [...parsedErrors, ...parsedDuplicateds];
  }

  public get summaryData(): SummaryData[] {
    return [
      {
        label: this.$t("reconciliation.sales.total_checked")
          .toString()
          .toUpperCase(),
        value: this.reconciliationData.summary.total_transactions
      },
      {
        label: this.$t("reconciliation.sales.to_reconcile")
          .toString()
          .toUpperCase(),
        value: this.reconciliationData.summary.total_transactions_to_reconcile
      },
      {
        label: this.$t("reconciliation.sales.sales_in_sync")
          .toString()
          .toUpperCase(),
        value:
          this.reconciliationData.summary.total_transactions -
          this.reconciliationData.summary.total_transactions_to_reconcile
      }
    ];
  }

  protected setPagination() {
    this.items = this.data.slice(0, this.tablePagination.itemsPerPage);
    this.pagination =
      this.data.length > 10
        ? { ...this.tablePagination, totalItems: this.data.length }
        : null;
  }

  protected changePagination(pagination: TablePagination) {
    this.items = this.data.slice(
      (pagination.currentPage - 1) * pagination.itemsPerPage,
      (pagination.currentPage - 1) * pagination.itemsPerPage +
        pagination.itemsPerPage
    );
  }

  protected async mounted() {
    this.data = this.parseSalesReconciliationsErrors(
      this.reconciliationData.errors,
      this.reconciliationData.duplicate_orders
    );
    this.setPagination();
    this.rowActions = retailSalesReconciliationActionService.getRowActions();
  }
}

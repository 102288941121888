import { ReconciliationDataResponse } from "@/interfaces/retailSalesReconciliation";
import { fnsFormatDate } from "@/utils/date-fns.utils";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

class RetailSalesReconciliationService extends HttpService {
  public async getReconciliation(
    date: Date
  ): Promise<ReconciliationDataResponse> {
    try {
      this.uri = "sale/orders/trc_reconciliation";
      const response = await super.post({
        date: fnsFormatDate(date)
      });

      return {
        success: true,
        data: response,
        errors: []
      };
    } catch (error) {
      return {
        success: false,
        data: null,
        errors: messagesService.parseMetrcError(error)
      };
    }
  }
  public async voidDuplicates(
    survivingReceiptID: number,
    transactionsToDelete: number[]
  ): Promise<any> {
    try {
      this.uri = "sale/orders/trc_void_duplicates";
      const response = await super.post({
        surviving_receipt_id: survivingReceiptID.toString(),
        transactions_to_delete: transactionsToDelete.map(toDelete =>
          toDelete.toString()
        )
      });

      return {
        success: true,
        data: response,
        errors: []
      };
    } catch (error) {
      return {
        success: false,
        data: null,
        errors: messagesService.parseMetrcError(error)
      };
    }
  }
}

export const retailSalesReconciliationService = new RetailSalesReconciliationService();

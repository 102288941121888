import { SummaryData } from "@/components/batch/adjust/models/batch-adjust.model";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./Summary.template.vue";

@Component({
  mixins: [Template]
})
export default class SummaryComponent extends Vue {
  @Prop({ required: true })
  public summary!: SummaryData[];
}

import { HelixPickerOptions } from "@/interfaces/retailSalesReconciliation";
import { HelixDatePickerComponent } from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./ChooseDay.template.vue";

const TODAY: Date = new Date();

@Component({
  mixins: [Template],
  components: {
    HelixDatePickerComponent
  }
})
export default class ChooseDayComponent extends Vue {
  @Prop({ required: true })
  public selectedDate!: Date;

  public currentDate: string = "";
  public datePickerOptions: HelixPickerOptions = {
    pickerOptions: {
      disabledDate(date: Date) {
        return date > TODAY;
      }
    }
  };

  public mounted() {
    this.currentDate = this.selectedDate.toISOString();
  }

  public nextStep() {
    this.$emit("nextStep", new Date(this.currentDate));
  }
}
